import { createSelector } from '@ngrx/store';
import { UserInfo } from 'app/shared/models';
import { AppState } from 'app/state/app.state';


export const selectUserInfo = (state: AppState) => state.userInfo;

export const selectUserDisplayLangId = createSelector(
  selectUserInfo,
  (state: UserInfo) => state.displayLanguageId
);
export const selectUserDisplayLangCode = createSelector(
  selectUserInfo,
  (state: UserInfo) => state.displayLanguageCode
);
export const selectUserGroupPermissions = createSelector(
  selectUserInfo,
  (state: UserInfo) => state.userPermission?.groupPermissions
)
export const selectUserAdminPermissions = createSelector(
  selectUserInfo,
  (state: UserInfo) => state.userPermission?.isAdmin
)

